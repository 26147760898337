import React, { useEffect } from "react";

// Components
import Layout from "components/Global/Layout";

const SocialMediaPage = () => {
	useEffect(() => {
		// Load the Curator script
		const script = document.createElement("script");
		script.src =
			"https://cdn.curator.io/published/b9c9e7d7-6f5d-47db-b668-2617213adee4.js";
		script.async = true;
		script.charset = "UTF-8";
		document.body.appendChild(script);

		// Cleanup function to remove the script when component unmounts
		return () => {
			document.body.removeChild(script);
		};
	}, []); // Empty dependency array means this effect runs once on mount

	return (
		<Layout
			pageName="social-media"
			amazon="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas"
		>
			<div className="social-media-page-container">
				<h2>ANCIENTGRAM</h2>
				<div id="curator-feed-default-feed-layout">
					<a
						href="https://curator.io"
						target="_blank"
						className="crt-logo crt-tag"
					>
						Powered by Curator.io
					</a>
				</div>
			</div>
		</Layout>
	);
};

export default SocialMediaPage;
